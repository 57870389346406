import PropTypes from 'prop-types';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useMediaQuery from 'hooks/useMediaQuery';
import useScrollPosition from 'hooks/useScrollPosition';

import { sendEvent } from 'lib/gtag';
import { scrollToTop } from 'lib/utils';

const SCROLL_POSITION_TO_SHOW_BUTTON_IN_PX = 310;
const SCROLL_POSITION_TO_SHOW_BUTTON_AFTER_LG_IN_PX = 660;

const ScrollToTopButton = ({ className, gaEventCategory = null }) => {
  const { isLg } = useMediaQuery();
  const [isOpen, setOpen] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      setOpen(
        currPos.y >=
          (isLg
            ? SCROLL_POSITION_TO_SHOW_BUTTON_AFTER_LG_IN_PX
            : SCROLL_POSITION_TO_SHOW_BUTTON_IN_PX)
      );
    },
    { deps: [isLg] }
  );

  const onClick = () => {
    if (gaEventCategory) {
      sendEvent({ action: 'go_to_top', category: gaEventCategory });
    }

    scrollToTop();
  };

  return (
    <Button
      className={twMerge(
        'sticky bottom-[calc(var(--navbar-height)_+_var(--navbar-space))] right-4 z-10 ml-auto lg:bottom-4',
        isOpen ? 'opacity-1 visible' : 'invisible opacity-0',
        className
      )}
      icon={<Icon name="keyboard-arrow-up" />}
      size="size2"
      type="primary"
      onClick={onClick}
    />
  );
};

ScrollToTopButton.propTypes = {
  gaEventCategory: PropTypes.string,
};

export default ScrollToTopButton;
